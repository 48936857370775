














































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'loan-recap',
  components: {},
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
