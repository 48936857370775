var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loan-recap"},[_c('div',{staticClass:"loan-recap__header"},[_c('span',{staticClass:"loan-recap__header__label table-label"},[_c('b',[_vm._v(_vm._s(_vm.content.title))]),_c('small',[_vm._v(_vm._s(_vm.content.id))])])]),_c('hr'),(_vm.type === 'loan')?[_c('ul',{staticClass:"loan-recap__list"},_vm._l((_vm.content.advances),function(loan){return _c('li',{key:loan.id,staticClass:"loan-recap__list__item loan-recap__list__block"},[_c('router-link',{staticClass:"loan-recap__block loan-link",attrs:{"to":{
            name: 'SingleLoan',
            params: { id: loan.id },
            query: _vm.$route.query,
          }}},[_c('span',{staticClass:"loan-recap__headline table-label"},[_vm._v(" "+_vm._s(_vm.content.subtitle)+" ")]),_c('div',{staticClass:"loan-recap__amount"},[_vm._v(" "+_vm._s(loan.amount)+" "),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"loan-recap__view-icon",attrs:{"size":"0 0 24 24","symbol":"icons-view"}})]),_vm._l((loan.credits),function(item,index){return _c('div',{key:index,staticClass:"loan-recap__footer"},[_c('span',{staticClass:"loan-recap__footer__label table-label"},[_vm._v(" "+_vm._s(item.id)+" "+_vm._s(_vm.$t('dashboard.last-date'))+" ")]),_c('span',{staticClass:"loan-recap__footer__date"},[_vm._v(" "+_vm._s(_vm.$dayjs(item.lastPayment).format('DD MMMM YYYY'))+" ")])])})],2)],1)}),0)]:_vm._e(),(_vm.type === 'mortgage')?[_c('ul',{staticClass:"loan-recap__list loan-recap__list__block"},_vm._l((_vm.content.advances),function(loan,idx){return _c('li',{key:loan.id,staticClass:"loan-recap__list__item"},[_c('div',{staticClass:"loan-recap__amount"},[_vm._v(_vm._s(loan.amount))]),_vm._l((loan.operations),function(op,index){return _c('router-link',{key:index,staticClass:"loan-recap__footer loan-link",attrs:{"to":{
            name: 'SingleMortgage',
            params: { id: loan.id, query: _vm.$route.query },
          }}},[_c('span',{staticClass:"loan-recap__footer__label table-label"},[_vm._v(" "+_vm._s(op.id)+" ")]),_c('div',{staticClass:"loan-recap__footer__amount table-label"},[_c('span',[_vm._v(_vm._s(op.amount))]),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"loan-recap__view-icon",attrs:{"size":"0 0 24 24","symbol":"icons-view"}})])])}),(idx < _vm.content.advances.length - 1)?_c('hr'):_vm._e()],2)}),0)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }